import { AuthStrategy, createAuthentication, KeycloakAuth } from '../../libs/auth-react'
import { CLIENT_ADDRESS, KEYCLOAK, SERVER_ADDRESS } from '../constants'
import { User } from './types'
import { useEffect, useState } from 'react'

const { pkceMethod, ...config } = KEYCLOAK

const getUser = async (authStrategy: AuthStrategy): Promise<User> => {
  const response = await fetch(new URL('/api/v1/user/whoami', SERVER_ADDRESS).toString(), {
    headers: authStrategy.toHeader(),
  })

  if (!response.ok) {
    console.error('Failed')
    throw new Error('Failed')
  }
  const user = (await response.json()) as User

  return user
}

export const KEYCLOAK_AUTH = new KeycloakAuth({
  config,
  frontendUri: CLIENT_ADDRESS,
  initOptions: {
    pkceMethod: pkceMethod as 'S256' | undefined,
  },
})

export const {
  AuthProvider,
  AuthContext,
  useAuth,
  useUser: useUserExternal,
} = createAuthentication<User>([KEYCLOAK_AUTH], getUser)

// TODO: Port back to MSAF client auth library
// Work around for bug in MSAF client library, Keycloak double fires the user event
export const useUser = () => {
  const [user, isLoading] = useUserExternal()
  const [state, setState] = useState<[User | undefined, boolean]>([undefined, true])

  useEffect(() => {
    if (user !== state[0]) {
      setState([user, isLoading])
    }
  }, [user])

  return state
}
