import React, { ReactNode, useEffect } from 'react'
import { Context, useCallback, useMemo, useState } from 'react'
import { AuthMethod } from './methods'
import { AuthContext, AuthEvent, AuthStrategy, WhoAmI } from './types'

export type AuthProviderState<T> = {
  user?: T
  error?: unknown
  authStrategy?: AuthStrategy
  isLoading: boolean
}

export const createAuthProvider =
  <T,>(AuthContext: Context<AuthContext<T> | null>, methods: [AuthMethod, ...AuthMethod[]], whoAmI: WhoAmI<T>) =>
  ({ children }: { children: ReactNode }) => {
    const [state, setState] = useState<AuthProviderState<T>>({ isLoading: true })

    const createAuthEventHandler = useCallback(
      (authMethod: AuthMethod) => (event: AuthEvent) => {
        if (event === 'authenticated') {
          const authStrategy = authMethod.authStrategy()
          ;(async () => {
            try {
              const user = await whoAmI(authStrategy)
              setState((prev) => ({ ...prev, user, error: undefined, isLoading: false, authStrategy }))
            } catch (error: unknown) {
              setState((prev) => ({ ...prev, user: undefined, error: error, isLoading: false, authStrategy }))
            }
          })()
        } else {
          setState((prev) => ({
            ...prev,
            user: undefined,
            isLoading: false,
            error: undefined,
          }))
        }
      },
      [whoAmI, setState],
    )

    const authMethod: AuthMethod = useMemo(() => {
      const selected = methods.find((m) => {
        m.setEventHandler(createAuthEventHandler(m))
        return m.use()
      })
      if (selected === undefined) throw new Error('No usable auth strategy.')
      return selected
    }, [methods, createAuthEventHandler])

    useEffect(() => authMethod.init(), [authMethod])

    const context = {
      ...state,
      isAuthenticated: state.user !== undefined,
      login: authMethod.login,
      logout: authMethod.logout,
      refresh: authMethod.refresh,
    }

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  }
