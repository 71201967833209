import { matchPath, useParams, Params, useLocation } from 'react-router-dom'
import { Crumb, CrumbProps } from './crumb'
import { Match } from './index'
import { LookupFieldOption } from '../form-fields/lookup'

interface GenericBreadcrumbsProps {
  matches: Match[]
  pathname: string
}

export function GenericBreadcrumbs({ matches, pathname }: GenericBreadcrumbsProps) {
  let isVisibleOverride = false
  const params = useParams()
  const { state } = useLocation()

  const getDynamicName = (match: Match, params: Readonly<Params<string>>) => {
    const paramName = match.handle?.breadcrumb?.paramName

    return !!paramName
      ? // using as string to indicate that the lookup will always return a matching value
        (match.data?.data?.find((lookupField: LookupFieldOption) => params[paramName] == lookupField.id)
          ?.description as string)
      : match.handle.breadcrumb.defaultName
  }

  const parseMatch = (match: Match): Omit<CrumbProps, 'index'> => {
    return !match.handle.breadcrumb.isParamRoute
      ? {
          name: match.handle.breadcrumb.defaultName,
          pathname: match.handle.breadcrumb.pathnameOveride ?? match.pathname,
        }
      : {
          name: getDynamicName(match, params),
          pathname: match.handle.breadcrumb.pathnameOveride ?? match.pathname,
        }
  }

  const breadcrumbs = matches.filter((route) => {
    const breadcrumb = route.handle?.breadcrumb
    isVisibleOverride = !!breadcrumb?.alwaysVisible
    return !!breadcrumb && (isVisibleOverride || !Boolean(matchPath(route.pathname, pathname)))
  })

  // Check if the current route is '/educator'
  const isEducatorRoute = !!matchPath(pathname, `/report/${params.reportId}/educator`)

  return (
    <nav className='flex' aria-label='Breadcrumb'>
      <ol role='list' className='flex items-center space-x-4'>
        {(breadcrumbs.length > 1 || isVisibleOverride) &&
          breadcrumbs.map((match, index) => {
            // Skip adding breadcrumbs for '/educator' route because we will update the base report breadcrumb
            if (isEducatorRoute && match.pathname === `/report/${params.reportId}/educator`) {
              return null
            }

            // Get the appropriate breadcrumb name and pathname
            const { name, pathname } = parseMatch(match)
            const validPathname = pathname as string

            let breadcrumbName: string
            let breadcrumbPathname: string

            // If it's the educator route, set the custom breadcrumb name and pathname
            if (isEducatorRoute) {
              breadcrumbName = 'report.learnerReports'
              breadcrumbPathname = '/report/learner'
            } else {
              // For the report route
              const isReportRoute = !!matchPath(match.pathname, `/report/${params.reportId}`)
              if (isReportRoute) {
                breadcrumbName = state?.name || name
                breadcrumbPathname = state?.reportPath || validPathname
              } else {
                // Default case for all other routes
                breadcrumbName = name
                breadcrumbPathname = validPathname
              }
            }

            return (
              <Crumb
                key={`${index}-${breadcrumbPathname}`}
                index={index}
                name={breadcrumbName}
                pathname={breadcrumbPathname}
              />
            )
          })}
      </ol>
    </nav>
  )
}
