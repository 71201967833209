import axios from 'axios'
import { useMemo } from 'react'
import { KEYCLOAK_AUTH } from '../auth'
import { SERVER_ADDRESS } from '../constants'

export const useRequest = () => {
  const client = useMemo(() => {
    const localClient = axios.create({
      baseURL: SERVER_ADDRESS,
    })

    localClient.interceptors.request.use(
      async (requestConfig) => {
        const controller = new AbortController()
        const requestConfigWithAbortSignal = {
          ...requestConfig,
          signal: controller.signal,
        }
        if (KEYCLOAK_AUTH.keycloak?.authenticated) {
          await KEYCLOAK_AUTH.refresh()
          if (KEYCLOAK_AUTH.token) {
            requestConfigWithAbortSignal.headers[
              'Authorization'
            ] = `${KEYCLOAK_AUTH.tokenIdentifier} ${KEYCLOAK_AUTH.token}`
          } else {
            // we only abort if we were authenticated and now are not, this will redirect us to the login page.
            // If we were never authenticated we try the request anyway.
            controller.abort('Token not found')
          }
        }
        return requestConfigWithAbortSignal
      },
      (error) => Promise.reject(error),
    )
    localClient.interceptors.response.use(
      (resp) => resp,
      (error) => {
        if (error.response?.status === 401) {
          KEYCLOAK_AUTH.keycloak?.clearToken()
        }
        return Promise.reject(error)
      },
    )
    return localClient
  }, [])

  return { client }
}
