import { AxiosError, AxiosInstance, HttpStatusCode } from 'axios'

interface DownloadFile {
  client: AxiosInstance
  endpoint: string
}

export const downloadFile = async ({ client, endpoint }: DownloadFile) => {
  const { data } = await client.get<{ url: string; originalName: string }>(endpoint)

  // Create a fake link on the page and click it.
  const fakeAnchorElement = document.createElement('a')
  fakeAnchorElement.href = '#fake'
  fakeAnchorElement.style.display = 'none'
  fakeAnchorElement.setAttribute('href', data.url)
  fakeAnchorElement.target = 'blank'
  fakeAnchorElement.rel = 'noopener noreferrer'
  fakeAnchorElement.download = data.originalName
  fakeAnchorElement.click()

  // Remove the element once its been used.
  setTimeout(() => fakeAnchorElement.remove(), 1000)
}
