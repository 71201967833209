import { useLocation, useSearchParams } from 'react-router-dom'
import { SimLaunchActions } from '../../../components/sim-launch-actions'
import { useCallback } from 'react'
import { AuthContext } from '../../../../libs/auth-react'
import { User } from '../../../auth/types'
import { LtiLaunchLoginMessage } from './login-message'

interface LtiLaunchContentProps extends Pick<AuthContext<User>, 'login'> {
  isAuthenticated: boolean
}

export function LtiLaunchContent({ isAuthenticated, login }: LtiLaunchContentProps) {
  const [searchParams] = useSearchParams()
  const simulationConfiguration = searchParams.get('configuration')
  const productProtocol = searchParams.get('protocol')
  const productId = searchParams.get('productId')
  const { pathname } = useLocation()
  const loginWithRedirect = useCallback(() => login({ redirectPath: `${pathname}?${searchParams}` }), [login, pathname])
  return isAuthenticated ? (
    <SimLaunchActions
      simulationConfiguration={simulationConfiguration}
      productProtocol={productProtocol}
      productId={productId}
      navigateTo={{
        pathname: '/lti/simulation/launch',
        search: searchParams.toString(),
      }}
    />
  ) : (
    <LtiLaunchLoginMessage loginWithRedirect={loginWithRedirect} />
  )
}
