import { useCallback, useMemo } from 'react'
import { Navigate } from 'react-router'
import { LoaderFunctionArgs, Params, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { RequestMethod, fetchClient } from './api'
import { ProtectedRoute } from './auth/protected-route'
import { PageLayout } from './components/page-layout'
import { useRequest } from './hooks/use-request'
import { AdminSection } from './routes/admin'
import { OrganisationDashboard } from './routes/admin/organisation'
import { UpdateOrganisation } from './routes/admin/organisation/[organisationId]'
import { LicensesDashboard } from './routes/admin/organisation/[organisationId]/license'
import { UpdateLicenseBlock } from './routes/admin/organisation/[organisationId]/license/[licenseId]'
import {
  LicenseDetails,
  processLicensePayload,
} from './routes/admin/organisation/[organisationId]/license/license-block-details-form'
import { NewLicenseBlock } from './routes/admin/organisation/[organisationId]/license/new'
import { OrganisationData } from './routes/admin/organisation/[organisationId]/organisation-details-form'
import { UsersDashboard } from './routes/admin/organisation/[organisationId]/user'
import { BulkAddUsersForm } from './routes/admin/organisation/[organisationId]/user/new'
import { NewOrganisation } from './routes/admin/organisation/new'
import { OrganisationWrapper } from './routes/admin/organisation/wrapper'
import { Products } from './routes/admin/product'
import { ProductConfiguration } from './routes/admin/product/[productId]'
import { UpdateProductConfiguration } from './routes/admin/product/[productId]/configuration/[configurationId]'
import { AddProductConfiguration } from './routes/admin/product/[productId]/configuration/new'
import { ProductConfigurationDetails } from './routes/admin/product/product-configuration-details'
import { DefaultRouteError } from './routes/default-route-error'
import { InProgress } from './routes/in-progress'
import { SimulationLaunch } from './routes/simulation/launch'
import { SimulationProductSelection } from './routes/simulation'
import { SimulationLaunchError } from './routes/simulation/simulation-error'
import { Product, UserDetails } from './types'
import { LookupFieldOptions } from './components/form-fields/lookup'
import { PracticeViewCategory, PracticeViewCategorySection } from './routes/simulation/[productId]/category'
import { SupportCentre } from './routes/support-centre'
import { UserPreferences, UserPreferencesData } from './routes/preference'
import { YourReport } from './routes/report/your-report'
import { Report } from './routes/report/[reportId]'
import { UserDetailsPage } from './routes/admin/organisation/[organisationId]/user/[userId]'
import { LtiLaunch } from './routes/simulation/lti-launch'
import { LtiError } from './routes/simulation/lti-launch/error'
import { LearnerReport } from './routes/report/learner-report'
import Logout from './routes/logout'
import { EvidenceAnalysis, EvidenceItem } from './routes/report/[reportId]/report-types'
import { PageLoader } from './components/loaders/page'
import { SessionNotSaved } from './routes/simulation/session-not-saved'
import BrightspaceUserMismatch from './routes/brightspace-user-mismatch'
import { UserPermissions } from './auth/types'
import { ProductWrapper } from './routes/admin/product/[productId]/wrapper'
import { DownloadSummary } from './routes/admin/product/[productId]/download'
import { NewDownload } from './routes/admin/product/[productId]/download/new'
import { UpdateDownload } from './routes/admin/product/[productId]/download/[id]'
import { DownloadDetails } from './routes/admin/product/[productId]/download/download-details-form'
import { Downloads } from './routes/downloads'
import { DownloadItemProps } from './routes/downloads/item'
import { SimulationNotInstalledError } from './routes/simulation/simulation-not-installed-error'
import { useAuth } from './auth'

type Endpoint = ((params: Params) => string) | string

type ActionProps<TPayload> = {
  method?: RequestMethod.POST | RequestMethod.PUT
  endpoint: Endpoint
  // TODO: Try and make sure the return type is same as TPayload
  // We are currently using `any` to accommodate the `processLicensePayload`
  // eslint-disable-next-line
  processPayload?: (payload: TPayload) => any
}

export const LTI_LAUNCH_HOME_ROUTE = `/lti/launch`

export const Router = () => {
  const { client } = useRequest()
  const auth = useAuth()

  const action = useCallback(
    <TData, TPayload>({ endpoint, method, processPayload }: ActionProps<TPayload>) =>
      async ({ request, params }: LoaderFunctionArgs) => {
        if (!client) {
          console.warn('No client set yet.')
          return
        }
        const payload = Object.fromEntries(await request.formData()) as unknown as TPayload
        return await fetchClient<TData>(client).createOrUpdateItem(
          typeof endpoint === 'string' ? endpoint : endpoint(params),
          processPayload ? processPayload(payload) : payload,
          method,
        )
      },
    [client],
  )

  const loader = useCallback(
    <TData,>(endpoint: Endpoint) => {
      return async ({ params }: LoaderFunctionArgs) => {
        if (!client) {
          console.warn('No client set yet.')
          return
        }
        const url = typeof endpoint === 'string' ? endpoint : endpoint(params)
        return await fetchClient<TData>(client).getItem(url)
      }
    },
    [client],
  )

  const simulationLaunchRoutes = useMemo(
    () => [
      {
        path: 'simulation/launch',
        errorElement: <SimulationLaunchError />,
        children: [
          {
            index: true,
            element: <SimulationLaunch />,
            loader: loader<Array<Product>>('/api/v1/product'),
          },
          {
            path: 'error',
            element: <SimulationNotInstalledError />,
          },
        ],
      },
    ],
    [loader],
  )

  const reportRoutes = useMemo(
    () => [
      {
        path: 'report',
        errorElement: <DefaultRouteError />,
        children: [
          {
            index: true,
            element: <YourReport />,
          },
          {
            path: ':reportId',
            errorElement: <DefaultRouteError />,
            loader: loader<Array<EvidenceItem>>(({ reportId }: Params) => `/api/v1/report/${reportId}`),
            element: <Report />,
            handle: {
              breadcrumb: {
                defaultName: 'report.yourReports',
                alwaysVisible: true,
                pathnameOveride: '/report',
                permission: [UserPermissions.VIEW_OWN_REPORTS],
              },
            },
            children: [
              {
                path: 'evidence/:evidenceId/analysis',
                action: action<EvidenceAnalysis, EvidenceAnalysis>({
                  endpoint: (params: Params) =>
                    `/api/v1/report/${params.reportId}/evidence/${params.evidenceId}/analysis`,
                  method: RequestMethod.PUT,
                }),
                element: <Navigate to='report/:reportId' replace />,
                handle: {
                  breadcrumb: {
                    alwaysVisible: true,
                    permission: [UserPermissions.VIEW_OWN_REPORTS],
                  },
                },
              },
              {
                path: 'educator',
                element: <Report />,
                handle: {
                  breadcrumb: {
                    defaultName: 'report.learnerReports',
                    alwaysVisible: true,
                    permission: [UserPermissions.VIEW_OWN_REPORTS],
                  },
                },
              },
            ],
          },
          {
            path: 'no-report',
            element: <SessionNotSaved />,
            handle: {
              breadcrumb: {
                defaultName: 'report.reports',
                alwaysVisible: true,
                permission: [UserPermissions.VIEW_OWN_REPORTS],
              },
            },
          },
          {
            path: 'learner',
            element: <LearnerReport />,
          },
        ],
      },
    ],
    [loader, action],
  )

  const router = useMemo(() => {
    if (auth.isLoading) return null
    return createBrowserRouter([
      {
        // The LTI launch should be accessible without auth.
        // Any action trigerred from this page will require the user to be authenticated.
        // Having this page allows us to check if the user is authenticated and then provide
        // a login button to authenticate themselves.
        path: 'lti/launch',
        errorElement: (
          <DefaultRouteError
            primaryLink={{
              to: LTI_LAUNCH_HOME_ROUTE,
              label: 'goBackHome',
            }}
          />
        ),
        element: <LtiLaunch />,
      },
      {
        // Fallback LTI error page incase the LTI launch fails for any reason
        path: 'lti/error',
        element: <LtiError />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
      {
        element: <ProtectedRoute />,
        errorElement: <DefaultRouteError />,
        children: [
          {
            path: 'lti',
            errorElement: (
              <DefaultRouteError
                primaryLink={{
                  to: LTI_LAUNCH_HOME_ROUTE,
                  label: 'goBackHome',
                }}
              />
            ),
            children: [...simulationLaunchRoutes, ...reportRoutes],
          },
          {
            element: <PageLayout />,
            errorElement: <DefaultRouteError />,
            children: [
              {
                index: true,
                element: <Navigate replace to='/simulation' />,
              },
              {
                path: 'brightspace-user-mismatch',
                element: <BrightspaceUserMismatch />,
              },
              {
                path: 'simulation',
                errorElement: <DefaultRouteError />,
                children: [
                  {
                    index: true,
                    element: <SimulationProductSelection />,
                    loader: loader<Array<Product>>('/api/v1/product'),
                  },
                  {
                    path: ':productId/*',
                    errorElement: <DefaultRouteError />,
                    element: <PracticeViewCategorySection />,
                    loader: loader<Array<PracticeViewCategory>>(({ productId }: Params) => {
                      return `/api/v1/product/${productId}/category`
                    }),
                  },
                ],
              },
              ...simulationLaunchRoutes,
              {
                path: 'support',
                element: <SupportCentre />,
              },
              {
                path: 'user/:userId/downloads',
                loader: loader<Array<DownloadItemProps>>(({ userId }: Params) => {
                  return `/api/v1/user/${userId}/simulation-download`
                }),
                element: <Downloads />,
              },
              {
                path: 'user/:userId/preference',
                element: <UserPreferences />,
                loader: loader<Array<UserPreferencesData>>(({ userId }: Params) => {
                  return `/api/v1/user/${userId}/preference`
                }),
                action: action<UserPreferencesData, UserPreferencesData>({
                  endpoint: (params: Params) => `/api/v1/user/${params.userId}/preference`,
                  method: RequestMethod.PUT,
                }),
              },
              ...reportRoutes,
              {
                path: 'admin',
                element: <AdminSection />,
                errorElement: <DefaultRouteError />,
                handle: {
                  breadcrumb: {
                    defaultName: 'administration',
                    pathnameOveride: '/admin/organisation',
                    permission: [UserPermissions.CLIENT_ACCESS_ADMIN_SECTION],
                  },
                },
                children: [
                  {
                    path: 'organisation',
                    errorElement: <DefaultRouteError />,
                    handle: {
                      breadcrumb: {
                        defaultName: 'organisations',
                        permission: [UserPermissions.VIEW_ORG, UserPermissions.CREATE_ORG, UserPermissions.CREATE_ORG],
                      },
                    },
                    children: [
                      {
                        index: true,
                        element: <OrganisationDashboard />,
                      },
                      {
                        path: 'new',
                        element: <NewOrganisation />,
                        action: action<OrganisationData, OrganisationData>({
                          endpoint: '/api/v1/organisation',
                        }),
                      },
                      {
                        path: ':organisationId',
                        element: <OrganisationWrapper />,
                        children: [
                          {
                            index: true,
                            element: <UpdateOrganisation />,
                            loader: loader<OrganisationData>(
                              (params: Params) => `/api/v1/organisation/${params.organisationId}`,
                            ),
                            action: action<OrganisationData, OrganisationData>({
                              endpoint: (params: Params) => `/api/v1/organisation/${params.organisationId}`,
                              method: RequestMethod.PUT,
                            }),
                          },
                          {
                            path: 'user',
                            handle: {
                              breadcrumb: {
                                defaultName: 'users',
                                permission: [
                                  UserPermissions.VIEW_USER,
                                  UserPermissions.CREATE_USER,
                                  UserPermissions.UPDATE_USER,
                                  UserPermissions.REMOVE_USER,
                                ],
                              },
                            },
                            children: [
                              {
                                index: true,
                                element: <UsersDashboard />,
                                errorElement: <DefaultRouteError />,
                              },
                              {
                                path: 'new',
                                element: <BulkAddUsersForm />,
                                action: action({
                                  endpoint: `/api/v1/user`,
                                  method: RequestMethod.POST,
                                }),
                                errorElement: <DefaultRouteError />,
                              },
                              {
                                path: ':userId',
                                element: <UserDetailsPage />,
                                loader: loader<UserDetails>(({ userId }: Params) => `/api/v1/user/${userId}`),
                                action: action<UserDetails, UserDetails>({
                                  endpoint: ({ userId }: Params) => `/api/v1/user/${userId}`,
                                  method: RequestMethod.PUT,
                                }),
                                errorElement: <DefaultRouteError />,
                              },
                            ],
                          },
                          {
                            path: 'license',
                            handle: {
                              breadcrumb: {
                                defaultName: 'licenses',
                                permission: [
                                  UserPermissions.VIEW_LICENSE_BLOCK,
                                  UserPermissions.CREATE_LICENSE_BLOCK,
                                  UserPermissions.UPDATE_LICENSE_BLOCK,
                                ],
                              },
                            },
                            children: [
                              {
                                index: true,
                                element: <LicensesDashboard />,
                              },
                              {
                                path: 'new',
                                element: <NewLicenseBlock />,
                                action: action<LicenseDetails, LicenseDetails>({
                                  endpoint: (params: Params) =>
                                    `/api/v1/organisation/${params.organisationId}/license-block`,
                                  method: RequestMethod.POST,
                                  processPayload: processLicensePayload,
                                }),
                              },
                              {
                                path: ':licenseId',
                                element: <UpdateLicenseBlock />,
                                loader: loader<Array<LicenseDetails>>(
                                  (params: Params) =>
                                    `api/v1/organisation/${params.organisationId}/license-block/${params.licenseId}`,
                                ),
                                action: action<LicenseDetails, LicenseDetails>({
                                  endpoint: (params: Params) =>
                                    `/api/v1/organisation/${params.organisationId}/license-block/${params.licenseId}`,
                                  method: RequestMethod.PUT,
                                  processPayload: processLicensePayload,
                                }),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'product',
                    errorElement: <DefaultRouteError />,
                    handle: {
                      breadcrumb: {
                        defaultName: 'productConfigurations',
                        permission: [
                          UserPermissions.VIEW_LICENSE_BLOCK,
                          UserPermissions.CREATE_LICENSE_BLOCK,
                          UserPermissions.UPDATE_LICENSE_BLOCK,
                        ],
                      },
                    },
                    children: [
                      {
                        index: true,
                        element: <Products />,
                        loader: loader<Array<Product>>('/api/v1/product'),
                      },
                      {
                        path: ':productId',
                        element: <ProductWrapper />,
                        loader: loader<LookupFieldOptions>('/api/v1/lookup?field=product'),
                        handle: {
                          breadcrumb: {
                            defaultName: 'productCategory',
                            isParamRoute: true,
                            permission: [
                              UserPermissions.VIEW_LICENSE_BLOCK,
                              UserPermissions.CREATE_LICENSE_BLOCK,
                              UserPermissions.UPDATE_LICENSE_BLOCK,
                            ],
                            paramName: 'productId',
                          },
                        },
                        children: [
                          {
                            index: true,
                            element: <ProductConfiguration />,
                          },
                          {
                            path: 'configuration',
                            children: [
                              {
                                path: 'new',
                                element: <AddProductConfiguration />,
                                action: action({
                                  endpoint: (params: Params) => `/api/v1/product/${params.productId}/configuration`,
                                }),
                              },
                              {
                                path: ':configurationId',
                                element: <UpdateProductConfiguration />,
                                loader: loader<Array<ProductConfigurationDetails>>(
                                  (params: Params) =>
                                    `api/v1/product/${params.productId}/configuration/${params.configurationId}`,
                                ),
                                action: action<ProductConfigurationDetails, ProductConfigurationDetails>({
                                  endpoint: (params: Params) =>
                                    `/api/v1/product/${params.productId}/configuration/${params.configurationId}`,
                                  method: RequestMethod.PUT,
                                }),
                              },
                            ],
                          },
                          {
                            path: 'download',
                            handle: {
                              breadcrumb: {
                                defaultName: 'downloads.heading',
                                permission: [
                                  UserPermissions.VIEW_USER,
                                  UserPermissions.CREATE_USER,
                                  UserPermissions.UPDATE_USER,
                                  UserPermissions.REMOVE_USER,
                                ],
                              },
                            },
                            children: [
                              {
                                index: true,
                                element: <DownloadSummary />,
                                errorElement: <DefaultRouteError />,
                              },
                              {
                                path: 'new',
                                element: <NewDownload />,
                                errorElement: <DefaultRouteError />,
                                action: action<DownloadDetails, DownloadDetails>({
                                  endpoint: (params: Params) =>
                                    `/api/v1/product/${params.productId}/simulation-download`,
                                  method: RequestMethod.POST,
                                }),
                              },
                              {
                                path: ':downloadId',
                                element: <UpdateDownload />,
                                errorElement: <DefaultRouteError />,
                                loader: loader<Array<DownloadDetails>>(
                                  (params: Params) =>
                                    `api/v1/product/${params.productId}/simulation-download/${params.downloadId}`,
                                ),
                                action: action<DownloadDetails, DownloadDetails>({
                                  endpoint: (params: Params) =>
                                    `/api/v1/product/${params.productId}/simulation-download/${params.downloadId}`,
                                  method: RequestMethod.PUT,
                                }),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: '/self-assessment',
                element: <InProgress />,
              },
            ],
          },
        ],
      },
    ])
  }, [action, loader, simulationLaunchRoutes, reportRoutes, auth.isLoading])

  if (!router) {
    return <PageLoader />
  }

  return <RouterProvider router={router} />
}
