import { AxiosError, AxiosInstance, AxiosResponse, HttpStatusCode } from 'axios'
import { KEYCLOAK_AUTH } from '../auth'

export const enum RequestMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export type DataError = Record<string, Array<string>>

export type ApiResponse<TData, TError = DataError> = {
  isSuccess: boolean
  data: TData
  isError: boolean
  error: TError
}

export const fetchClient = <TData, TError = DataError>(client: AxiosInstance) => {
  const handleSuccess = (response: AxiosResponse): ApiResponse<TData, null> => {
    return {
      isSuccess: true,
      data: response.data,
      isError: false,
      error: null,
    }
  }

  const handleError = (error: unknown): ApiResponse<null, TError> => {
    if (error instanceof AxiosError && error.response?.status) {
      if (error.response.status === HttpStatusCode.BadRequest) {
        return {
          isError: true,
          error: error.response?.data,
          isSuccess: false,
          data: null,
        }
      } else if (error.response.status === HttpStatusCode.Forbidden) {
        // Throwing a 404 lets us make use of react routers error boundaries and redirects the user
        // to the closest error boundary
        throw new Response('Not Found', { status: 404 })
      }
    }
    throw error
  }

  const getItem = async (path: string) => {
    try {
      const response = await client.get(path)
      if ([HttpStatusCode.Ok].includes(response.status)) {
        return handleSuccess(response)
      }
    } catch (error) {
      return handleError(error)
    }
  }

  const postItem = async <TPayload>(path: string, payload: TPayload) => {
    try {
      const response = await client.post(path, payload)
      if ([HttpStatusCode.Created].includes(response.status)) {
        return handleSuccess(response)
      }
    } catch (error) {
      return handleError(error)
    }
  }

  const putItem = async <TPayload>(path: string, payload: TPayload) => {
    try {
      const response = await client.put(path, payload)
      if ([HttpStatusCode.Ok].includes(response.status)) {
        return handleSuccess(response)
      }
    } catch (error) {
      return handleError(error)
    }
  }

  const createOrUpdateItem = async <TPayload>(
    path: string,
    payload: TPayload,
    method: RequestMethod.POST | RequestMethod.PUT = RequestMethod.POST,
  ) => {
    if (method === RequestMethod.PUT) {
      return await putItem(path, payload)
    } else {
      return await postItem(path, payload)
    }
  }

  return {
    getItem,
    postItem,
    putItem,
    createOrUpdateItem,
  }
}
